<template>
    <div
    id="notification-bell-icon-div"
    >
        <svg 
            width="20" 
            height="20" 
            viewBox="0 0 40 40"
            version="1.1"
            fill="red"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
    <g id="Group_2510" data-name="Group 2510" transform="translate(-1021 -13)">
    <circle id="Ellipse_67" data-name="Ellipse 67" cx="19" cy="19" r="18.5" transform="translate(1021 13)" fill="" class="notificationColor"/>
    <path id="Path_725" data-name="Path 725" d="M12,22a2.006,2.006,0,0,0,2-2H10A2,2,0,0,0,12,22Zm6-6V11c0-3.07-1.64-5.64-4.5-6.32V4a1.5,1.5,0,0,0-3,0v.68C7.63,5.36,6,7.92,6,11v5L4.71,17.29A1,1,0,0,0,5.41,19H18.58a1,1,0,0,0,.71-1.71Z" transform="translate(1028 19)" fill="red" class="fillblackwhite"/>
    </g>
        </svg>
    </div>
  </template>
  
  <script>

  export default {
    name: "notiSwitch",
  };
  </script>
  <style lang="scss">
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";

  .notificationColor{
    fill:$clr-lang-switch-icon;
  }
  .notiBell{
    fill:$clr-notibell-fill;
  }
  .fillblackwhite{
        fill:$clr-saina-topnav-btn
   }
  </style>